<template>
	<v-card class="info__card elevation-0" color="transparent">
		<h4>Informations article</h4>
		<v-list class="elevation-0" color="transparent" two-line>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-subtitle>Créé le</v-list-item-subtitle>
					<v-list-item-title>{{ formatedDate(article.created) }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider inset></v-divider>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-subtitle>Modifié le</v-list-item-subtitle>
					<v-list-item-title>{{ formatedDate(article.updated) }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider inset></v-divider>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-subtitle>Lien</v-list-item-subtitle>
					<a target="_blank" :href="article.url">{{ article.url }}</a>
				</v-list-item-content>
			</v-list-item>
			<v-divider inset></v-divider>
		</v-list>
	</v-card>
</template>


<script>
import { utils } from '../../utils.js'

export default {
	name: 'infos',
	props: ['article'],
	data() {
		return {
		}
	},
	methods: {
		formatedDate(date) {
			return utils.tools.formatedDate(date)
		}
	}
}
</script>


<style lang="scss" scoped>
.info__card {
	h4 {
		padding: 10px 20px;
		color: white;
		font-weight: normal;
		background: #272727;
	}

	a {
		color: lightblue !important;
	}
}
</style>